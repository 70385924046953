import React, { useEffect } from "react"
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom"
import DefaultLayout from "./DefaultLayout"
import Login from "@components/Login/Login"
import { BrandingProvider } from "./branding/BrandingContext"
import { AuthProvider, useAuth } from "@auth/AuthContext"
import Landing from "@pages/Landing"
import Feed from "@pages/Feed"
import Favourites from "@pages/favourites/Favourites"
import Contact from "@pages/Contact"

const App = () => {
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        if (event.data && event.data.type === "UPDATE_STATE") {
          console.log("Message received from service worker:", event.data)
          // Update your state here based on the event data
        }
      })
    }
  }, [])

  return (
    <BrowserRouter>
      <BrandingProvider>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </BrandingProvider>
    </BrowserRouter>
  )
}

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<PublicRoute element={<Login />} />} />

      <Route path="/" element={<PrivateRoute element={<DefaultLayout />} />}>
        <Route index element={<Landing />} />
        <Route path="feed" element={<Feed />} />
        <Route path="favourites" element={<Favourites />} />
        <Route path="contact" element={<Contact />} />
      </Route>
    </Routes>
  )
}

const PrivateRoute: React.FC<{ element: JSX.Element }> = ({ element }) => {
  const { user } = useAuth()

  return user ? element : <Navigate to="/login" />
}

const PublicRoute: React.FC<{ element: JSX.Element }> = ({ element }) => {
  const { user } = useAuth()

  return user ? <Navigate to="/" /> : element
}

export default App
