import { adjustColourBrightness } from "./color"
import { IBranding } from "./models"

export const setDocumentStyles = (branding: IBranding) => {
  const root = document.documentElement
  const colours = branding.colours
  const fonts = branding.fonts

  const percentageAdjustment = 5

  // Handle color variables
  for (const key in colours) {
    const lightenColor = adjustColourBrightness(
      colours[key],
      percentageAdjustment
    )
    const darkenColor = adjustColourBrightness(
      colours[key],
      -percentageAdjustment
    )
    root.style.setProperty("--header-height", "70px")
    root.style.setProperty("--footer-height", "70px")
    root.style.setProperty("--nav-height", "70px")
    root.style.setProperty("--side-padding", "8%")
    root.style.setProperty(`--${key}`, colours[key])
    root.style.setProperty(`--${key}-lighten`, lightenColor)
    root.style.setProperty(`--${key}-darken`, darkenColor)
  }

  let primaryFontCSS = ""

  // for (const key in fonts) {
  //   const font = fonts[key]
  //   const fontName = key
  //   const fontFam = new FontFace(`${fontName}`, `url(${font.publicURL})`, {})
  //   document.fonts.add(fontFam)

  //   if (key === "primaryFont") {
  //     primaryFontCSS = `
  //       * {
  //         font-family: '${fontName}', sans-serif;
  //       }
  //     `
  //   }
  // }

  // // Inject primary font as the default for the body
  // if (primaryFontCSS) {
  //   const styleElement = document.createElement("style")
  //   styleElement.innerHTML = primaryFontCSS
  //   document.head.appendChild(styleElement)
  // }
}
