import { ITheme } from "./types"
import { IBranding } from "@utils/models"

export const createTheme = (branding: IBranding): ITheme => ({
  colours: branding.colours,
  fonts: {
    primaryFont: branding.fonts?.primaryFont?.publicURL,
    secondaryFont: branding.fonts?.secondaryFont?.publicURL,
  },
  logos: {
    light: branding.logos.lightColourLogo.publicURL,
  },
  borderStyle: branding.borderStyle,
  bgImage: branding?.bgImage?.url,
})
