import axios from "axios"
import { IBranding } from "./models"
import urlBase64ToUint8Array from "./functions"

const SERVER_URL = import.meta.env.VITE_PAYLOAD_SERVER_URL
const VAPID_KEY = import.meta.env.VITE_VAPID_PUBLIC_KEY
const INVENTORY_ID = import.meta.env.VITE_PAYLOAD_INVENTORY_ID
const PROJECT_ID = import.meta.env.VITE_PAYLOAD_PROJECT_ID
const ORGANISATION_ID = import.meta.env.VITE_PAYLOAD_ORGANISATION_ID

export const CUSTOMERS_URL = `${SERVER_URL}/api/customers`
export const INVENTORY_URL = `${SERVER_URL}/api/inventory`
export const FEED_URL = `${SERVER_URL}/api/feed`
export const PROJECTS_URL = `${SERVER_URL}/api/projects/data`
export const ORGANISATION_URL = `${SERVER_URL}/api/organisations`

export const loginCustomer = async (
  QRID: string
): Promise<{
  success: boolean
  message: string
  user: any | null
}> => {
  try {
    const response = await axios.get(`${CUSTOMERS_URL}/${QRID}/login`)
    const data = response.data

    if (!data.success) {
      return { success: false, message: data.message, user: null }
    }

    return { success: true, message: "Login successful", user: data.data }
  } catch (error) {
    console.error("Error during login:", error)
    return { success: false, message: "Login failed", user: null }
  }
}

export const updateCustomerVisits = async (QRID: string): Promise<any> => {
  try {
    const response = await axios.patch(`${CUSTOMERS_URL}/${QRID}/visits`, {
      headers: {
        "Content-Type": "application/json",
        "x-project-id": PROJECT_ID,
      },
    })

    if (response.status !== 200) {
      throw new Error("Network response was not ok")
    }

    const data = response.data

    return data
  } catch (error) {
    console.error("Fetch error:", error)
  }
}

export const fetchCustomerFavourites = async (QRID: string): Promise<any> => {
  try {
    const response = await axios.get(`${CUSTOMERS_URL}/${QRID}/favourites`, {
      headers: {
        "Content-Type": "application/json",
        "x-project-id": PROJECT_ID,
      },
    })

    if (response.status !== 200) {
      throw new Error("Network response was not ok")
    }

    const data = response.data

    return data?.units
  } catch (error) {
    console.error("Fetch error:", error)
  }
}

export const fetchFeed = async (): Promise<any> => {
  try {
    const response = await axios.get(`${FEED_URL}/${PROJECT_ID}`)

    if (response.status !== 200) {
      throw new Error("Network response was not ok")
    }

    return response.data?.docs
  } catch (error) {
    console.error("Fetch error:", error)
  }
}

export const getBranding = async (): Promise<IBranding | null> => {
  try {
    const response = await axios.get(`${PROJECTS_URL}/${PROJECT_ID}`)

    if (response.status === 404) {
      console.error("Error 404: Resource not found")
      return null
    }

    if (response.status !== 200) {
      throw new Error(`Unexpected status code: ${response.status}`)
    }

    const branding = response?.data?.pages?.find(
      (page: any) => page?.relationTo === "branding"
    )?.value

    console.log("Branding data:", branding)

    const data = branding || null
    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 404) {
        console.error("Error 404: Resource not found")
        return null
      } else {
        console.error("Fetch error:", error.message)
      }
    } else {
      console.error("Unexpected error:", error)
    }
    return null
  }
}

export const updateCustomerSubscription = async (
  subscription: PushSubscription | null,
  userID: string
) => {
  if (!subscription) {
    console.error("No subscription available to update the customer.")
    return null
  }

  try {
    const response = await axios.patch(
      `${CUSTOMERS_URL}/${userID}`,
      {
        appInstalled: true,
        notificationsEnabled: true,
        subscription,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )

    if (response.status !== 200) {
      console.error("Failed to update customer", response.data)
      return null
    }

    console.log("Customer updated successfully", subscription)
    return response.data
  } catch (error) {
    console.error("Error updating customer subscription:", error)
    return null
  }
}

export const requestNotificationPermission = async (
  userID: string
): Promise<string> => {
  try {
    // Step 1: Request notification permission from the user
    const permission = await Notification.requestPermission()

    // Step 2: Handle different permission outcomes
    if (permission === "granted") {
      // Step 3: Check if service worker is ready
      if (!("serviceWorker" in navigator)) {
        console.error("Service workers are not supported by this browser.")
        return "Service workers are not supported by this browser."
      }

      // Step 4: Wait for the service worker to be ready, handle any failure
      const registration = await navigator.serviceWorker.ready.catch(
        (error) => {
          console.error("Service worker registration failed", error)
          return null
        }
      )

      if (!registration) {
        console.error("Service worker registration is not available.")
        return "Service worker registration is not available."
      }

      // Step 5: Try to subscribe the user to push notifications
      let subscription: PushSubscription | null = null
      try {
        subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(VAPID_KEY),
        })
        console.log("Push subscription successful", subscription)
      } catch (subscriptionError) {
        console.error("Push subscription failed:", subscriptionError)
        return "Failed to subscribe for push notifications."
      }

      // Step 6: Update the subscription on your server
      const updatedCustomer = await updateCustomerSubscription(
        subscription,
        userID
      )

      if (updatedCustomer) {
        console.log("Push notifications have been enabled for the user.")
        return "Push notifications enabled successfully."
      } else {
        return "Failed to update customer subscription."
      }
    } else if (permission === "denied") {
      console.log("Permission for notifications was denied.")
      return "Notification permission was denied."
    } else {
      console.log("Permission for notifications was dismissed.")
      return "Notification permission was dismissed."
    }
  } catch (error) {
    console.error("Error requesting notification permission:", error)
    return `Error: ${error.message}`
  }
}

// reload page to start listening for push events in the react app thread,
// so we can update the UI when a push event is received (e.g. update the feed or favs)
// window.location.reload()
