import styled from "styled-components"

function LoadScreen(props) {
  return (
    <LoadingWrapper {...props}>
      <span className="loader"></span>
    </LoadingWrapper>
  )
}

export default LoadScreen

const LoadingWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: calc(infinity);
  top: 0;
  left: 0;

  display: grid;
  place-content: center;

  .loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    background: linear-gradient(
      0deg,
      rgba(255, 61, 0, 0.2) 33%,
      var(--secondary) 100%
    );
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: var(--primary);
  }
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
